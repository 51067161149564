@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html {
  background-color: #ffffff;
  color: #1f1235;
}

body {
  margin: 0;
  font-family: 'Open Sans', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  height: 100%;
}

#content {
  min-height: calc(100vh - 600px);
  margin-left: 0;
}

code {
  font-family: 'Open Sans', 'Helvetica', sans-serif;
}

@media (max-width: 600px) {
  #header_rk9_logo {
    height: 100% !important;
    width: 100% !important;
  }

}